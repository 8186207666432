varying vec2 vUv;
varying float vAngle;

// rotate x
mat3 rotationX(float angle) {
    return mat3(
        1.0, 0.0, 0.0,
        0.0, cos(angle), -sin(angle),
        0.0, sin(angle), cos(angle)
    );
}

void main() {

    // World Position
    vec4 transformedModel = modelMatrix * vec4(position, 1.0);

    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    vUv = uv;

    vec3 pos = position;
    //vec3 cam = rotationX(0.3) * cameraPosition;
    vec3 cam = cameraPosition;

    vec3 up = vec3(0.0, 1.0, 0.0);
    vec3 view = normalize(cam - pos);
    vAngle = 1.0 - pow(1.0 - abs(dot(up, view)), 4.0);
}
