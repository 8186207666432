export default [
    // {
    //     name: 'backgroundSound',
    //     type: 'audio',
    //     path: '/sounds/background.mp3'
    // },
    {
        name: 'circleModel',
        type: 'gltfModel',
        path: '/models/circle.glb',
        meta: {
            "type": "gltfModel"
        }
    },
    {
        name: 'points1Model',
        type: 'gltfModel',
        path: '/models/points_1.glb',
        meta: {
            "type": "gltfModel"
        }
    },
    {
        name: 'points2Model',
        type: 'gltfModel',
        path: '/models/points_2.glb',
        meta: {
            "type": "gltfModel"
        }
    },
    {
        name: 'points3Model',
        type: 'gltfModel',
        path: '/models/points_3.glb',
        meta: {
            "type": "gltfModel"
        }
    },
    {
        name: 'cloudsAttribute',
        type: 'jsonModel',
        path: '/models/clouds.json',
        meta: {
            "type": "jsonModel"
        }
    },

    // {
    //     name: 'clouds1Attribute',
    //     type: 'json',
    //     path: '/attributes/clouds_1.json'
    // },
    // {
    //     name: 'clouds2Attribute',
    //     type: 'json',
    //     path: '/attributes/clouds_2.json'
    // },
    // {
    //     name: 'clouds3Attribute',
    //     type: 'json',
    //     path: '/attributes/clouds_3.json'
    // },
    // {
    //     name: 'uvTexture',
    //     type: 'texture',
    //     path: '/textures/uv.png'
    // },
    // {
    //     name: 'noiseTexture',
    //     type: 'texture',
    //     path: '/textures/noise.png'
    // },
    {
        name: 'smearNoiseTexture',
        type: 'texture',
        path: '/textures/T_SmearNoise006.png'
    },
    {
        name: 'nebulaTexture',
        type: 'texture',
        path: '/textures/nebula.jpg'
    },
    {
        name: 'nebula1Texture',
        type: 'texture',
        path: '/textures/nebula_1.jpg'
    },
    {
        name: 'nebula2Texture',
        type: 'texture',
        path: '/textures/nebula_2.jpg'
    },
    {
        name: 'displacementTexture',
        type: 'texture',
        path: '/textures/displacement.jpg'
    }
]
