uniform float uAlpha;

varying vec3 vColor;
varying float vNoise;


void main() {
    vec2 uv = gl_PointCoord.xy;

    float alpha = 1.0 - length(uv - 0.5);
    if (alpha < 0.5) discard;
    alpha = pow(alpha, 8.0 + vNoise * 2.0);
    alpha = pow(alpha, 2.0) * vNoise;

    vec3 color = vColor + pow(alpha, 3.0);

    alpha *= uAlpha;

    gl_FragColor = vec4(color, alpha);
}
