import * as THREE from 'three'
import Experience from './Experience.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import gsap from "gsap";

export default class Camera
{
    constructor()
    {
        this.experience = new Experience()
        this.input = this.experience.input
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.time = this.experience.time
        this.canvas = this.experience.canvas
        this.timeline = this.experience.timeline
        this.cursorEnabled = true

        this.lerpVector = new THREE.Vector3();
        this.lerpFactor = 0.9

        this.setInstance()
        //this.setControls()
    }

    setInstance()
    {
        this.instance = new THREE.PerspectiveCamera(25, this.sizes.width / this.sizes.height, 0.1, 300)
        this.defaultCameraPosition = new THREE.Vector3(-0.5, 20.5, 7);

        this.instance.position.copy(this.defaultCameraPosition)
        this.instance.lookAt(new THREE.Vector3(0, 0, 0));

        this.lerpVector.copy(this.instance.position);

        this.scene.add(this.instance)
    }

    setControls()
    {
        this.controls = new OrbitControls(this.instance, this.canvas)
        this.controls.enableDamping = true
        this.controls.minDistance = 0;
        this.controls.maxDistance = 500;
        this.controls.enabled = true;
        this.controls.target = new THREE.Vector3(0, 0, 0);


        // this.controls.mouseButtons = {
        //     LEFT: THREE.MOUSE.ROTATE,
        //     MIDDLE: null,
        //     RIGHT: null,  // Отключает действие для правой кнопки мыши
        // };
        //
        // this.controls.enableZoom = false;

    }

    resize()
    {
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix()
    }

    update()
    {
        if (this.cursorEnabled === true) {

            const lerpTarget = new THREE.Vector3();
            const targetX = this.input.cursor.x;
            const targetY = this.input.cursor.y + 2.5;

            lerpTarget.set(targetX, targetY, this.instance.position.z)


            const t = 1 - Math.exp(-this.lerpFactor * this.time.delta);
            this.lerpVector.lerp(lerpTarget, t );
        }

        this.instance.position.x = this.lerpVector.x;
        this.instance.position.y = this.lerpVector.y;
        this.instance.position.z = this.lerpVector.z;

        //this.controls.update()

        this.instance.updateMatrixWorld() // To be used in projection
        this.instance.lookAt(0, 0, 0);
    }

    animateCameraPosition() {

    }
}
